import { render, staticRenderFns } from "./Teaser.vue?vue&type=template&id=47441b5e&scoped=true"
import script from "./Teaser.vue?vue&type=script&lang=js"
export * from "./Teaser.vue?vue&type=script&lang=js"
import style0 from "./Teaser.vue?vue&type=style&index=0&id=47441b5e&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47441b5e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Richtext: require('/vercel/path0/components/elements/Richtext.vue').default,Link: require('/vercel/path0/components/elements/Link.vue').default,AnimationCustomFadeIn: require('/vercel/path0/components/elements/Animations/CustomFadeIn.vue').default,Section: require('/vercel/path0/components/elements/Section.vue').default})
