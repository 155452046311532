
import SVGHips from '../../assets/svg/hips.svg';
import SVGJoint from '../../assets/svg/joint.svg';
import SVGRobotik from '../../assets/svg/robotik.svg';
import SVGArrowRight from '../../assets/svg/ArrowRight.svg';

export default {
  components: {
    SVGHips,
    SVGJoint,
    SVGRobotik,
    SVGArrowRight
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  }
};
